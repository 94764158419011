import styled from "styled-components";

const P = styled.p`
  font-size: 1em;
  line-height: 1.4em;
  font-weight: 300;
  color: inherit;
`;

export default P;
