import styled, { css } from "styled-components";
import { theme } from "@upsolve/ui";

const extraProps = css`
  ${(props) => (props.color != null ? `color: ${props.color};` : "")}
  ${(props) => (props.textAlign != null ? `text-align: ${props.textAlign};` : "")}
`;

export const H0 = styled.h1`
  color: ${theme.colors.black["300"]};
  font-size: 60px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.5px;
  ${extraProps}
  @media screen and (max-width: 45em) {
    font-size: 44px;
    line-height: 44px;
  }
`;

export const H1 = styled.h1`
  color: ${theme.colors.black["300"]};
  font-size: 52px;
  font-weight: 500;
  line-height: 58px;
  letter-spacing: -0.5px;
  ${extraProps}
  @media screen and (max-width: 45em) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const H2CSS = css`
  color: ${theme.colors.black["300"]};
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  @media screen and (max-width: 45em) {
    font-size: 32px;
    line-height: 32px;
  }
`;

export const H2 = styled.h2`
  ${H2CSS}
  ${extraProps}
`;

export const H3CSS = css`
  color: ${theme.colors.black["300"]};
  font-size: 2em;
  line-height: 45px;
  font-weight: 400;
  @media screen and (max-width: 45em) {
    font-size: 27px;
    line-height: 29px;
  }
`;

export const H3 = styled.h3`
  ${H3CSS}
  ${extraProps}
`;

export const H4CSS = css`
  color: ${theme.colors.black["300"]};
  font-size: 1.5em;
  line-height: 36px;
  font-weight: 400;
  @media screen and (max-width: 45em) {
    font-size: 23px;
    line-height: 23px;
  }
`;

export const H4 = styled.h4`
  ${H4CSS}
  ${extraProps}
`;

export const H5CSS = css`
  color: ${theme.colors.black["300"]};
  font-size: 1.25em;
  line-height: 1.5em;
  font-weight: 400;
  @media screen and (max-width: 45em) {
    font-size: 18px;
    line-height: 18px;
  }
`;

export const H5 = styled.h5`
  ${H5CSS}
  ${extraProps}
`;

export const H6CSS = css`
  color: ${theme.colors.black["300"]};
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 400;
  @media screen and (max-width: 45em) {
    font-size: 13px;
    line-height: 13px;
  }
`;

export const H6 = styled.h6`
  ${H6CSS}
  ${extraProps}
`;
