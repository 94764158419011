import React from "react";
import styled from "styled-components";

import CTASideBySideOfferings from "../components/CTA/CTASideBySideOfferings";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import P from "../components/Type/Paragraph.p";
import trackComponentAction from "../components/analytics/trackComponentAction";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import getMyUpsolveEntryUrl from "../components/Links/getMyUpsolveEntryUrl";
import { MyUpsolveLink } from "../components/Links/MyUpsolveLink";
import { H2 } from "../components/Type/Display.h";
import { HeroText } from "../components/Layout/Hero.div";
import {CTATarget} from '../components/CTA/getCTAData'

const Wrapper404 = styled(HeroText)`
  margin-top: 4em;
  margin-bottom: 4em;
  max-width: 640px;
  text-align: center;
`;

const trackingProps = {
  componentName: "404",
  componentVersion: "0",
};

class PageNotFound extends React.Component {
  render() {
    return (
      <TopLevelLayout>
        <div className="index-container">
          <SEO title="Page Not Found" />
          <MainHeader />
          <main>
            <Wrapper404>
              <H2>Sorry, this page no longer exists!</H2>
              <div>
                <P>
                  If you're in financial distress and are considering bankruptcy, our{" "}
                  <MyUpsolveLink
                    href={getMyUpsolveEntryUrl({ triggeredByActionId: "404.myUpsolveNavigation" })}
                    onClick={() =>
                      trackComponentAction({
                        ...trackingProps,
                        actionId: "404.myUpsolveNavigation",
                        actionMethod: "click",
                      })
                    }
                  >
                    free online nonprofit service may be able to help you
                  </MyUpsolveLink>
                  .
                </P>
              </div>
            </Wrapper404>
          </main>
          <CTASideBySideOfferings trackingPageContext = {trackingProps} ctaTarget = {CTATarget.DEBT_TRIAGE}/>
          <MainFooter />
        </div>
      </TopLevelLayout>
    );
  }
}

export default PageNotFound;
